import React from 'react';

const IconBoldTextInlineItem = ({img, bold, text, boldColor, className}) => {
    return (
        <div className={`icon-bold-text-item ${className}`}>
            <div className="image-wrapper">
                {img && <img src={img} alt=""/>}
            </div>
            <div className="text-wrapper">
                {bold && <h2 className={`${boldColor !== 'green' ? boldColor : ''}`}>{bold}</h2>}
                {text && <div dangerouslySetInnerHTML={{__html: text}} />}
            </div>
        </div>
    )
};

IconBoldTextInlineItem.defaultProps = {
    bold: null,
    text: null,
    boldColor: 'green',
    className: ''
}

export default IconBoldTextInlineItem;