import React from 'react';
import CatoImg from '../cato-image';
import './style.scss';

const ScrollingImageSection = ({openingText, items, isMobile}) => {
    return (
        <section className="scrolling-image-section">
            <div className="content-wrap">
                {openingText && <div className="opening-text" dangerouslySetInnerHTML={{__html: openingText}}/>}
                {items.length &&
                    <ul className="scroll-list">
                        {items.map((item, i) => (
                            <li key={`scroll-list-item-${i}`}>
                                <div>
                                <CatoImg
                                    img={isMobile ? item.mobileImg : item.img}
                                />
                                </div>
                            </li>
                        ))}
                    </ul>
                }
            </div>
        </section>
    )
};

export default ScrollingImageSection;