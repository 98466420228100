import React, {useEffect, useState} from 'react';
import { graphql } from "gatsby";
import Layout from '../components/layout';
import GlobalPartnerProgram from '../assets/images/global-partner/partners logo.svg'
import GlobalPartnerProgramBlack from '../assets/images/global-partner/partners_logo_black_text.svg'
import HeroRings from '../assets/images/global-partner/hero rings.svg';
import CatoImg from '../components/cato-image';
import Marketo from '../components/marketo';
import IconBoldTextInlineItem from '../components/icon-bold-text-inline';
import {PARTNER_FORM_ID, IS_MOBILE, LANG} from "../common/consts"
import InsightsBanner from '../components/insights-banner';
import ScrollingImageSection from '../components/scrolling-image-section';
import {resourcesTrioMapper} from '../common/utils';
import TestimonyBlock from '../components/testimony-quote-block';
import "../assets/styles/pages/global-partners.scss";

const QuoteSection  = ({onlyQuote, quote, source, title, img, wrap}) => (
    <section className={`quote${onlyQuote ? ' only-quote' : ''}${wrap ? ' wrap' : ''}`} >
        <TestimonyBlock
            logo={img}
            quote={quote}
            source={source}
            title={title}
            wrap={wrap}
        />
    </section>
);

const GlobalPartners = ({data, location, pageContext}) => {
    const {wpPage} = data;
    const {globalPartners, resourcesTrioFooter} = wpPage;

    const coolInsights = resourcesTrioFooter && resourcesTrioFooter.resourcesTrio ? resourcesTrioMapper( resourcesTrioFooter.resourcesTrio.resourcesTrioHeadline, resourcesTrioFooter.resourcesTrio.resourcesTrioResources) : null;

    const mapLogos = globalPartners.globalPartnersIncludeLogos ? globalPartners.globalPartnersLogosGroup.logos.map((item) => (
        {
            img: item.logo,
            mobileImg: item.logoMobile
        }
    )).filter(item => item.img !== null) : null;

    const quoteStrip = globalPartners.globalPartnersQuoteStrip;

    const wrapQuote = globalPartners.globalPartnersIconListGroup && globalPartners.globalPartnersIconListGroup.length < 2;

    const quote = globalPartners && globalPartners.globalPartnersIncludeQuote
        ?
        {
            quote: quoteStrip.quote,
            source: quoteStrip.quoteeName,
            title: quoteStrip.quoteeTitle,
            headline: quoteStrip.quoteStripTitle,
            img: quoteStrip.quoteCompanyLogo ? quoteStrip.quoteCompanyLogo : null,
            wrap: wrapQuote
        }
        :
        null;

    const [isMobile, setMobile] = useState(false);

    useEffect(() => {
        if(IS_MOBILE) {
            setMobile(true);
        }
    }, []);

    return (
        <Layout
            className={'global-partners-page'}
            yoastData={wpPage.seo}
            whiteMenu={false}
            seoTitle={wpPage.pageSeoTitle.pageSeoTitle}
            seoPath={location.pathname}
            title={wpPage.title}
            lang={pageContext.lang}
            translations={pageContext.translations}
            wpid={wpPage.databaseId}

        >
            <section className="banner">
                {!isMobile && <CatoImg img={GlobalPartnerProgram} className="partners-program-logo"/>}
                <div className="text extra-left-padding">
                    <h1>{wpPage.title}</h1>
                    {globalPartners.globalPartnersBannerText && <p>{globalPartners.globalPartnersBannerText}</p>}
                </div>
                {
                    !isMobile &&
                    <CatoImg img={HeroRings} className="rings"/>
                }
            </section>
            <section className="benefits-list">
                <div className="list extra-left-padding">
                    {
                        globalPartners.globalPartnersIconListGroup.map((group, i) => (
                            <>
                            {group.boldText && <h3>{group.boldText}</h3>}
                            {group.iconList && group.iconList.length &&
                                <ul>
                                    {group.iconList.map((item, j) => (
                                        <li key={`item-list-${i}-${j}`}>
                                            <IconBoldTextInlineItem
                                                img={item.icon.localFile.publicURL || null}
                                                bold={item.boldText || null}
                                                text={item.description || null}
                                                boldColor="text"
                                                className="text"
                                            />
                                        </li>
                                    ))}
                                </ul>
                            }
                            </>
                        ))
                    }
                    {/*{quote && wrapQuote && <QuoteSection {...quote}/>}*/}
                </div>
                <div className="form-wrapper">
                    {isMobile && <CatoImg img={GlobalPartnerProgramBlack} style={{height: '53px'}}/>}
                    {globalPartners.globalPartnersFormHeader && <h2>{globalPartners.globalPartnersFormHeader}</h2>}
                    <Marketo formId={PARTNER_FORM_ID} />
                </div>
            </section>
            {quote && <QuoteSection {...quote}/>}
            {mapLogos && <ScrollingImageSection isMobile={isMobile} items={mapLogos} openingText={globalPartners.globalPartnersLogosGroup.text || ''} />}
            {coolInsights && <InsightsBanner className="blocks" headline={coolInsights.headline} data={coolInsights.data} navArrows={false} slidesToShow={isMobile ? 2 : 3} slideSettings={{variableWidth: true, infinite: true, centerPadding: '0', centerMode: isMobile ? false : true, slidesToScroll: 1}} />}
        </Layout>
    )
}

export default GlobalPartners;

export const pageQuery = graphql`
    query globalPartnersTemplatePageQuery($id: String!) {
        wpPage(id: { eq: $id }) {
            ...YoastData
            title
            content
            slug
            databaseId
            id
            date(formatString: "MMMM DD, YYYY")
            path: uri
            pageSeoTitle {
                pageSeoTitle
            }
            globalPartners {
                globalPartnersBannerText
                globalPartnersFormHeader
                globalPartnersIconListGroup {
                    boldText
                    iconList {
                        icon {
                            localFile {
                                publicURL
                            }
                        }
                        boldText
                        description
                    }
                }
                globalPartnersIncludeCoolInsightsStrip
                globalPartnersIncludeLogos
                globalPartnersLogosGroup {
                    text
                    logos {
                        logo {
                            altText
                            localFile {
                                publicURL
                            }
                        }
                        logoMobile: logo {
                            altText
                            localFile {
                                childImageSharp {
                                    fixed(height: 30) {
                                        ...GatsbyImageSharpFixed_withWebp_noBase64
                                    }
                                }
                            }
                        }
                    }
                }
                globalPartnersIncludeQuote
                globalPartnersQuoteStrip {
                    quoteStripTitle
                    quote
                    quoteeName
                    quoteeTitle
                    quoteCompanyLogo: companyLogo {
                        altText
                        localFile {
                            publicURL
                        }
                    }
                }
            }
            ...StickyBanner
            ...VideoSection
            ...ResourcesTrio
            ...GartnerStrip
    }
}`